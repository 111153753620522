.salsify-to-bf-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .salsify-to-bf-col {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .custom-field-map-row {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .custom-field-map-row-margin:not(:last-child) {
    margin-bottom: 24px;
  }

  .delete-mapping-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0 24px;
  }
}

@media (max-width: 1038px) {
  .salsify-to-bf-container {
    .salsify-to-bf-col {
      align-items: flex-start;
      .custom-field-map-row {
        align-items: flex-start;
        flex-direction: column;
        width: 300px;

        .sals-bff-arrow-right {
          display: none;
        }
      }
    }
    .responsive-row-margin {
      margin-top: 24px;
    }
    .delete-mapping-container {
      width: 300px;
      margin-top: 24px;
      margin-bottom: 0;
      margin-left: 0;
      justify-content: flex-end;
    }
    .optional-mapping-row-border {
      padding-bottom: 24px;
      border-bottom: solid 1px #dcdee5;
      width: 300px;
    }
    .custom-field-map-row-margin:not(:last-child) {
      margin-bottom: 36px;
    }
  }
}
@media (max-width: 768px) {
  .salsify-to-bf-container {
    .salsify-to-bf-col {
      .custom-field-map-row {
        width: 100%;
      }
    }
    .delete-mapping-container {
      width: 100%;
    }
    .optional-mapping-row-border {
      width: 100%;
    }
  }
}
