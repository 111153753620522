#salsify-product-id {
  width: 100%;
}

.forms-content-container {
  .salsify-form-row {
    .salsify-product-id-container {
      .salsify-form-row-item {
        min-height: 74px;
      }
      
      div.salsify-product-id-item {
        width: 100%;
      }
    }
  }

  #product-id-label {
    margin-bottom: 6px;
  }
}
