.add-client-link-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.select-clients-type-combobox {
  width: 300px;
}

@media (max-width: 768px) {
  .select-clients-type-combobox {
    width: 100%;
  }
}
