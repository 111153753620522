.review-section {
  padding: 32px;
  border: 1px solid #c1c4cf;
  border-radius: 4px;
  margin-bottom: 24px;
  line-height: 22px;
  min-width: 360px;

  .edit-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .review-section-title {
      font-size: 24px;
    }

    .edit-text {
      color: #4462c9;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .review-attribute {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .review-attribute-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 160px;
      min-width: 160px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      margin-right: 24px;
      margin-bottom: 4px;
    }

    .review-attribute-value {
      color: #6f6f6f;
      max-width: 100%;
      line-height: 16px;
      word-wrap: break-word;

      li {
        margin-bottom: 8px;
      }
    }
  }
  @media (max-width: 567px) {
    .review-attribute {
      flex-direction: column;
    }
  }
  .review-attribute:not(:last-child) {
    margin-bottom: 20px;
  }
}
.review-section:last-child {
  .review-section-title {
    margin-right: 4px;
    width: 180px;
  }
}
.review-page-header {
  margin-bottom: 24px;
  margin-top: 52px;
}
