.menu-card-popover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .option-button {
    justify-content: start;
    height: 13px;
    padding: 8px;
    min-height: 36px;
  }
  .menu-card-item {
    cursor: pointer;
  }
}
