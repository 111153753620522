.container-selector-container {
  width: 100%;
  min-height: 50px;
  min-width: 150px;
  background-color: white;
  position: relative;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(206, 206, 206, 1);
  border-radius: 6px;
  margin-top: 4px;
  padding: 8px 12px 8px 12px;
  cursor: pointer;
  transition: all 0.3s;

  .container-folder-selected-text {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
  .container-folder-placeholder-text {
    color: #959bac;
  }
  .container-selector-popup {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 52px;
    left: 0px;
    width: 100%;
    max-height: 250px;
    overflow: auto;
    background-color: white;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.12);
    z-index: 100;
    .container-selector-popup-header {
      display: flex;
      position: sticky;
      top: 0px;
      flex-direction: row;
      justify-content: space-between;
      padding: 4px 8px 4px 4px;
      border-bottom: 1px rgba(206, 206, 206, 0.5) solid;
      background-color: white;
      z-index: 2;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
      cursor: default;
      .container-selector-popup-header-text {
        padding-top: 4px;
      }
      .container-selector-popup-nav-button {
        &:hover {
          background-color: rgba(206, 206, 206, 0.25);
          transition: background-color 0.2s ease-in-out;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .container-selector-popup-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 4px 4px 12px;
      border-bottom: 1px rgba(206, 206, 206, 0.5) solid;
      cursor: pointer;
      &:hover {
        background-color: rgba(206, 206, 206, 0.15);
        transition: background-color 0.2s ease-in-out;
      }
      .container-selector-popup-option-button {
        &:hover {
          background-color: rgba(206, 206, 206, 0.6);
          transition: background-color 0.2s ease-in-out;
        }
        background-color: rgba(206, 206, 206, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 28px;
        width: 28px;
        height: 28px;
        border-radius: 4px;
        margin-left: 6px;
        cursor: pointer;
      }
    }
  }
  span.bf-icon {
    color: #6b7280;
  }
}
.container-selector-container:hover {
  border-color: #9b9b9b;
}
.container-selector-container-disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: #9b9b9b;
}
.container-selector-popup-pagination {
  display: flex;
  justify-content: center;
}
