.exit-worfklow-modal-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(200, 200, 200, 0.8);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;

  .exit-workflow-modal {
    display: flex;
    flex-direction: column;
    width: 560px;
    background: white;
    border-radius: 4px;
    margin: auto;
    opacity: 1.5;
    .exit-workflow-modal-header {
      padding: 24px;
      font-weight: 700;
      background: #fafbfc;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      .icon-close {
        cursor: pointer;
      }
      .exit-workflow-modal-header-left {
        display: flex;
        flex-direction: row;
        align-items: center;

        .exit-workflow-modal-header-left-text {
          font-size: 20px;
        }
      }
      .exit-workflow-modal-header-close-icon {
        cursor: pointer;
      }
    }
    .exit-workflow-modal-body {
      padding: 24px;
      .exit-workflow-modal-actions {
        margin-top: 36px;
        display: flex;
        flex-direction: row;
        justify-content: end;
      }
    }
  }
}
