.workfront-optional-metadata-container {
  width: 100%;
  background-color: #fafbfc;
  border-radius: 4px;
  border: 1px solid #f3f5fa;
  margin-top: 52px;
  padding: 24px;

  .workfront-optional-metadata-content {
    max-width: 300px;

    .project-forms-tagger-container {
      margin-top: 25px;
    }

    .bf-combobox__combobox--focused {
      box-shadow: none;
    }

    .optional-metadata-label {
      font-size: 12px;
      font-weight: 500;
      color: #6c7182;
      letter-spacing: 0.5px;
    }

    .workfront-forms-label-container {
      display: flex;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 9px;
      font-weight: 500;
    }
  }

  @media (max-width: 567px) {
    .workfront-optional-metadata-content {
      max-width: none;
    }
  }
}
