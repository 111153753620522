.page-header {
  display: flex;
  flex-direction: column;
  margin-top: 44px;

  .page-header-title {
    font-size: 32px !important;
  }

  .page-header-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 52px;
    .page-header-filters {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
    }
  }
}
