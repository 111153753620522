.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding: 25px 44px;

  img {
    height: 30px;
    align-self: flex-start;
  }

  .links {
    .sign-out {
      cursor: pointer;
    }
  }
}
