.workflows-no-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 8px;

  .workflows-no-results-header {
    font-size: 20px;
    margin-bottom: 12px;
  }
}
