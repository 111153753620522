.delete-mapping-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 17px;

  &:hover {
    cursor: pointer;
  }
}
