.workflow-manager-banner {
  display: flex;
  flex-direction: column;

  .main-heading {
    font-size: 40px !important;
    line-height: 40px !important;
    font-weight: 700 !important;
  }

  .subheading {
    font-size: 20px !important;
    font-weight: 450 !important;
    margin-top: 28px !important;
  }

  .gradient-rule {
    background: linear-gradient(
      to left,
      rgba(68, 98, 201, 0) 0%,
      rgba(68, 98, 201, 0.25) 60%,
      rgba(68, 98, 201, 1) 100%
    );
    height: 2px;
    // The 80px accounts for the 40px of padding on both sides
    width: calc(100% + 80px);
    position: relative;
    margin-top: 44px;
    left: -40px;
  }
}
