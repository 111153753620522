.clients-page {
  padding: 40px;
  min-width: 644px;
  .clients-pagination-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 36px;
  }
  .clients-empty-state-container {
    margin-top: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .clients-empty-state-header {
      font-size: 20px !important;
      font-weight: 700 !important;
    }
    .clients-empty-state-message {
      margin-top: 12px;
      max-width: 400px;
      font-size: 14px;
      font-weight: 450;
      text-align: center;
    }
  }

  .client-page-table {
    margin-top: 24px;
    .client-page-table-border-bottom tr:last-child {
      border-bottom: 1px solid #e6e6e6;
    }
    .client-row-header:not(:first-child) {
      text-align: right;
    }
    .client-row-container {
      cursor: pointer;
    }
    @media screen and (min-width: 768px) {
      .last-updated-column,
      .integrations-column {
        text-align: right;
      }
    }
  }
}
