.sync-row-container {
  display: flex;

  .sync-row-content-container {
    margin-left: 30px;
    width: 100%;

    .sync-row-content-divider {
      margin: 24px 0;
      width: 100%;
      border-color: #dcdee5;
    }

    .workflow-sync-row-section-label {
      color: #6c7182;
      padding-bottom: 18px;
      letter-spacing: 0.75px;
    }
  }
  .seismic-add-mapping {
    width: fit-content;
    cursor: pointer;
  }
}

@media (max-width: 1038px) {
  .section-to-folder-sync-row,
  .collection-to-folder-sync-row {
    .sync-row-content-container {
      .sync-row-content-divider {
        display: none;
      }

      .workflow-sync-row-section-label {
        margin-top: 36px;
      }
    }
  }
}
