.create-workflow-input-field-container {
  display: flex;
  flex-direction: column;

  .create-workflow-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .create-workflow-input-field-input {
    width: 100%;
  }
  .create-workflow-tooltip-icon {
    padding-left: 5px;
    display: flex;
    align-items: center;
  }
}
.create-workflow-input-field-container:not(:first-child) {
  margin-top: 32px;
}
