p.asana-section-headings.bf-text {
  margin-top: 52px;
  font-size: 32px;
  line-height: 36px;
}
  
p.asana-description-text {
  margin-top: 24px;
  line-height: 18px;
  max-width: 685px;
}
  
.asana-page-selector-container {
  max-width: 300px;
  margin-top: 52px;

  .text-input, .bf-select {
    width: 100%;
  }

  .multi-select-placeholder {
    color: #9b9b9b
  }

  .asana-text-sub {
    color: #6c7182;
    margin-top: 8px; 
    font-size: 12px;
    line-height: 16px; 
  }

  .bf-tooltip__standard--wrapper {
    margin-top: 4px;
    display: flex;
  }

  #asana-select-tags-textarea {
    min-height: 100px;
  }

  #select-custom-fields{
    div {
      max-width: 300px;
    }

    .multi-select-dropdown {
      overflow: auto;
    }
  }
}

  
@media (max-width: 768px) {
  .asana-page-selector-container {
    max-width: 100%;
  }
}
  
  