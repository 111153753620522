.info-banner-container {
  display: flex;
  background-color: #eef6fd;
  border: solid 1px #d4eafa;
  border-radius: 4px;
  padding: 16px;
  .icon-info-filled {
    filter: brightness(0) saturate(100%) invert(48%) sepia(90%) saturate(329%) hue-rotate(164deg)
      brightness(90%) contrast(94%);
  }
  .info-banner-text-container {
    margin-left: 16px;

    .info-banner-header-text {
      color: #101524 !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      line-height: 20px !important;
      margin-bottom: 8px;
    }
    .info-banner-body-text {
      color: #353a4a;
      font-weight: 450px;
      line-height: 20px;
    }
    .info-banner-body-link {
      color: #4462c9;
      font-weight: 500;
      line-height: 20px;
      margin-top: 13px;
      text-decoration: none;
    }
  }
}
