p.wrike-section-headings.bf-text {
  margin-top: 52px;
  font-size: 32px;
  line-height: 36px;
}

p.wrike-description-text {
  margin-top: 24px;
  line-height: 18px;
  margin-bottom: 38px;
  max-width: 520px;
}

.wrike-page-selector-container {
  max-width: 300px;
}

@media (max-width: 768px) {
  .wrike-page-selector-container {
    max-width: 100%;
  }
}

.wrike-radio-buttons {
  display: flex;
  input {
    height: 16px;
  }
}
.wrike-radio-button-disabled {
  input {
    border-color: #c1c4cf;
  }
}
