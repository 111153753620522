.tracker-container {
  padding: 20px;
  font-size: 16px;
  list-style: circle;
  background: #f3f5fa;
  width: 320px;
  display: flex;
  justify-content: center;
  align-items: start;

  .tracker-list-container {
    padding-top: 36px;

    .track-item {
      list-style: none;
      padding-bottom: 28px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &.complete {
        color: blue;

        ::before {
          content: '&#10003';
        }
      }

      .icon-check-filled {
        filter: brightness(0) saturate(100%) invert(51%) sepia(56%) saturate(495%)
          hue-rotate(118deg) brightness(103%) contrast(89%);
      }

      .active-icon {
        width: 12px;
        height: 12px;
        background: #4462c9;
        border-radius: 50%;
        transform: scale(1.333);
      }

      .inactive-icon {
        width: 12px;
        height: 12px;
        background: #bfcdfa;
        border-radius: 50%;
      }

      .icon-success-checkmark {
        border-radius: 50%;
      }

      p.tracker-item-title {
        padding-left: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .tracker-container {
    display: none;
  }
}
