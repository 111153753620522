.optional-custom-field-mapping-container {
  .optional-mapping-row {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    margin-top: 27px;

    .optional-mapping-row-item {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-right: 24px;
    }
  }
}
