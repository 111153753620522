.optional-mapping-row-item {
  width: 100%;

  .select-custom-field-key-dest-config {
    width: 100%;
  }

  .select-custom-field-key-custom-field-map {
    width: 100%;
  }
}

@media (max-width: 1038px) {
  .optional-custom-field-mapping-container {
    .optional-mapping-row {
      align-items: flex-start;
      .optional-mapping-row-item {
        max-width: 300px;
      }
    }
  }
}

@media (max-width: 768px) {
  .optional-mapping-row-item {
    max-width: 100%;
  }
}
