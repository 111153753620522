.oauth-page {
  background-color: #f9fafc;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0 44px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    position: absolute;
    width: 127.63px;
    height: 30px;
    left: 44px;
    top: 44px;
  }

  .oauth-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;

    .loader-container {
      margin: 70px auto;
    }

    .oauth-page-header-text {
      text-align: center;
    }

    .oauth-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      margin-top: 56px;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      .oauth-card-header-text {
        font-size: 32px;
        text-align: center;
        line-height: 30px;
      }
      .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 48px;
      }

      .sign-in-button,
      .try-again-button {
        padding: 16px 20px;
        font-size: 16px;
        font-weight: 500;
      }

      .try-again-button {
        width: auto;
      }
    }
  }
}
