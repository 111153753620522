.credential-forms-header {
  margin-bottom: 24px;
  margin-top: 52px;
}

.cred-forms-details {
  max-width: 300px;
  margin-bottom: 20px;

  .divider-container {
    display: flex;
    flex-direction: row;
    margin: 20px 0;

    span {
      margin: 0 15px;
    }

    .divider {
      margin: 10px 0;
      height: 1px;
      background: #e0e0e0;
      width: 100%;
    }
  }
}

.no-exisiting-credentials-container {
  padding-bottom: 36px;
  .no-exisiting-credentials-form {
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .no-exisiting-credentials-container {
    .no-exisiting-credentials-form {
      max-width: 100%;
    }
  }
}
