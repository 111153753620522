.create-credential {
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  .create-credential-form-container {
    background: white;
    opacity: 2;
    display: flex;
    flex-direction: column;
    width: 460px;
    border-radius: 4px;

    height: auto;
    max-height: 95vh;
    overflow-y: auto;

    .create-credential-form-head {
      padding: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #fafbfc;

      .create-credential-form-head-text {
        font-size: 24px;
      }

      .icon-close {
        background: url('assets/close/close-x.svg') center no-repeat;
      }

      .add-client {
        align-items: center;
        font-weight: bold;

        .icon-add {
          margin-right: 10px;
        }
      }
    }

    .create-credential-content {
      display: flex;
      flex-direction: column;
      padding: 24px;

      label.text-danger {
        font-size: 12px;
        margin-top: 5px;
      }
    }

    .create-credential-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 24px;
      margin-top: 12px;

      button {
        margin-left: 12px;
      }
    }

    input {
      width: 100%;
    }
  }
}
