.forms-content-container {
  .brandfolder-to-salsify-container {
    margin-bottom: 16px;
  }
  .bf-select {
    &:hover {
      cursor: pointer;
    }
  }

  p.workflow-form-salsify-header {
    margin-bottom: 52px;
    margin-top: 52px;
    font-size: 32px;
    line-height: 36px;
  }

  p.workflow-form-salsify-optional-header {
    margin-bottom: 24px;
  }

  .workflow-form-salsify-sub-header {
    margin-bottom: 52px;
  }

  .salsify-form-row {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    margin-top: 52px;

    .salsify-form-row-item {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;
    }
    @media (max-width: 768px) {
      .salsify-form-row-item {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  @media (max-width: 1038px) {
    .responsive-row {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  @media (min-width: 769px) {
    .salsify-brandfolder-container {
      max-width: 300px;
    }
  }

  .salsify-org-label {
    margin-top: 52px;
  }

  .sals-bff-arrow-right {
    margin: 0 35px 18px;
  }

  .bff-tool-tip {
    margin: 0 5px;
  }

  .optional-custom-field-mapping-container {
    background-color: #fafbfc;
    border-radius: 4px;
    padding: 24px;
    margin-top: 52px;
    border: solid 1px #f3f5fa;

    .delete-mapping-container {
      margin: 0;
      button {
        background-color: #fafbfc;
      }
      button:hover {
        background-color: #ad2334;
      }
    }

    @media (max-width: 1038px) {
      .optional-mapping-row {
        flex-direction: column;
        margin-top: 0;
        align-items: flex-start;

        .optional-mapping-row-border {
          padding-bottom: 24px;
          border-bottom: solid 1px #dcdee5;
          width: 300px;
        }

        .optional-mapping-row-item {
          margin-top: 24px;
          margin-right: 0;
        }

        .delete-mapping-container {
          display: flex;
          width: 300px;
          margin-top: 24px;
          justify-content: flex-end;
        }
      }
      .optional-mapping-row:not(:last-child) {
        margin-bottom: 12px;
      }
    }
    @media (max-width: 768px) {
      .optional-mapping-row {
        .optional-mapping-row-item {
          max-width: 100%;
          width: 100%;
        }
        .optional-mapping-row-border {
          width: 100%;
        }
        .delete-mapping-container {
          width: 100%;
        }
      }
    }
  }

  .workflow-shimmer-loader {
    span {
      height: 48px;
      width: 300px;
    }
  }
  
  .workflow-shimmer-loader-narrow {
    span {
      width: 200px;
    }
  }

  .select-default-salsify-property {
    font-size: 12px;
    font-weight: 500;
    color: #6c7182;
    letter-spacing: 0.5px;
  }
}
