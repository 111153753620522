.optional-mapping-row-item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  .select-salsify-dest-config {
    width: 100%;
  }

  .select-salsify-custom-field-map {
    width: 100%;
  }
}
