.seismic-init-info-container {
  max-width: 300px;
}
@media (max-width: 768px) {
  .seismic-init-info-container {
    max-width: 100%;
  }
}
.sync-option-section {
  display: flex;
  flex-direction: column;
  background-color: #fafbfc;
  border: #f3f5fa solid 1px;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .sync-option-radio-button {
      height: 16px;
      margin: 0 !important;
      margin-right: 16px !important;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
        opacity: 0.25;
      }
    }
  }

  .entire-bf-sync-row-section {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-bottom: 8px;
    width: 300px; 
    padding-top: 18px;

    .sync-row-section-label {
      margin-bottom: 8px;
      font-size: 14px;
      color: #4A4A4A;
      line-height: 22px;
      font-family: 'Circular-Bold','Arial','Trebuchet MS',sans-serif;
      font-weight: 700;
    }
  }
}
.sync-option-section {
  label {
    span.truncate {
      overflow: auto;
      text-overflow: inherit;
      white-space: normal;
      line-height: 18px;
    }
  }
}
.workflow-input-container {
  margin-top: 53px;
}
p.sync-options-section-header {
  font-size: 24px;
  margin-top: 52px;
  margin-bottom: 24px;
}
