.sync-row-mapping-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;

  button.sync-row-map-trash-icon {
    margin-top: 30px;
    margin-left: 25px;
    background-color: #fafbfc;
  }
  button.sync-row-map-trash-icon:hover {
    background-color: #ad2334;
  }
}

.sync-row-selection {
  .sync-row-selection-label {
    margin-bottom: 8px;
  }
}
.section-to-folder-sync-row-selection,
.collection-to-folder-sync-row-selection {
  width: 100%;
  min-width: 224px;
}
.sync-row-selection-arrow {
  padding-top: 48px;
  margin: 0 35px 25px;
}

@media (max-width: 1038px) {
  .sync-row-mapping-row {
    flex-direction: column;
    align-items: flex-start;
    width: 300px;

    .sync-row-selection-margin.section-to-folder-sync-row-selection,
    .sync-row-selection-margin.collection-to-folder-sync-row-selection {
      margin-top: 24px;
    }

    .section-to-folder-sync-row-selection,
    .collection-to-folder-sync-row-selection {
      width: 300px;
    }

    .sync-row-selection-arrow {
      display: none;
    }

    button.sync-row-map-trash-icon {
      align-self: flex-end;
      margin: 0;
      margin-top: 24px;
    }
  }
  .section-to-folder-sync-row-map,
  .collection-to-folder-sync-row-map {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: solid 1px #dcdee5;
  }
}

@media (max-width: 768px) {
  .sync-row-mapping-row {
    width: 100%;
    .section-to-folder-sync-row-selection,
    .collection-to-folder-sync-row-selection {
      width: 100%;
    }
  }
}
