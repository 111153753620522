.highspot-source-selection-container {
  .multi-select-button {
    width: 300px;
    margin: 7px 0;
  }
  @media (max-width: 768px) {
    .multi-select-button {
      width: 100%;
      max-width: 100%;
    }
  }
}
