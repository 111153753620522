.create-workflow-form-container {
  height: 100%;
  width: 100%;
  select,
  input {
    height: 40px;
  }

  .workflow-wizard-section {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 83px;
    width: 100%;

    .forms-container {
      display: flex;
      justify-content: center;
      width: 100%;

      .form-label {
        color: #101524;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 6px;
      }

      .forms-content-container {
        max-width: 872px;
        padding: 44px 16px 20px 16px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .select-integration-type-combobox {
          max-width: 300px;
        }

        .forms-header {
          margin-bottom: 24px;
          margin-top: 52px;
        }

        // Overrides the style of BFSelect and BFMultiSelect to match the
        // StandardCombobox style
        select.bf-select,
        div.multi-select-button,
        input.bf-date-select {
          min-height: 50px;
          border-radius: 6px;
          transition: border-color 0.3s;
          align-items: center;
          padding-left: 12px;
          background-color: white;
        }
        select.bf-select:hover,
        div.multi-select-button:hover,
        input.bf-date-select:hover {
          border-color: #9b9b9b;
        }
        select.bf-select:focus,
        div.multi-select-button:focus,
        input.bf-date-select:focus {
          border-color: #4a4a4a;
          box-shadow: none;
        }
        input.bf-date-select {
          cursor: pointer;
        }
      }
    }
  }

  @media (min-width: 568px) {
    .workflow-wizard-section {
      .forms-container {
        .forms-content-container {
          padding: 56px 44px 56px 44px;
        }
      }
    }
  }

  .bff-tool-tip {
    line-height: 12px;
  }

  .integration-forms-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .workflow-info-section {
    border: 1px solid #c1c4cf;
    border-radius: 4px 4px 4px 4px;
    margin-top: 52px;

    .workflow-info-section-header {
      display: flex;
      align-items: center;
      padding: 24px;
      background: #fafbfc;
      border-bottom: 1px solid #c1c4cf;
      border-radius: 4px 4px 0px 0px;
      font-size: 20px;
      line-height: 24px;
      color: #101524;
    }

    .workflow-info-section-body {
      padding: 32px 28px;
      display: flex;
      flex-direction: column;

      .workflow-info-section-content {
        max-width: 300px;

        .workflow-field {
          display: flex;
          flex-direction: column;
          p.workflow-field-sublabel {
            color: #6f6f6f;
            font-size: 12px;
            line-height: 16px;
            margin-top: 4px;
          }
        }
        .workflow-field-margin {
          margin-bottom: 36px;
        }
      }
      @media (max-width: 768px) {
        .workflow-info-section-content {
          max-width: 100%;
        }
      }
    }

    .add-new-client-button {
      color: #4462c9;
    }
  }
}

input.error {
  border-color: red;

  &:hover:not(:focus) {
    border-color: red;
  }
}

// moves tooltips to foreground, useful for modals
#layers {
  z-index: 1000;
}
