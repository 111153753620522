.create-workflow-nav {
  display: flex;
  left: 0;
  right: 0;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 16px;
  align-items: center;
  border-bottom: 1px solid #dcdee5;
  position: fixed;
  background-color: #ffffff;
  z-index: 3;

  .create-workflow-nav-title {
    display: none;
  }

  .create-workflow-nav-left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-close-thin {
      cursor: pointer;
    }

    .vertical-divider-line {
      display: none;
      border-left: 1px solid #dcdee5;
      border-right: none;
      border-top: none;
      border-bottom: none;
      margin-right: 20px !important;
      margin-left: 36px !important;
    }
  }
}

@media (min-width: 568px) {
  .create-workflow-nav {
    padding: 16px 44px;
    .create-workflow-nav-title {
      display: block;
    }
    .create-workflow-nav-left {
      .vertical-divider-line {
        display: block;
      }
    }
  }
}
