.credential-select {
  .credential-select-header {
    margin-bottom: 24px;
    font-size: 24px;
  }

  #select-credentials {
    width: 100%;
    height: 48px;
    margin: 5px 0;
  }
}
