#select-default-salsify-property {
  width: 100%;
}

@media (min-width: 769px) {
  #select-default-salsify-property {
    width: 300px;
  }
}

.salsify-property-label-wrapper {
  margin-bottom: 4px;
}
