.modal-error-container {
  margin: 0 24px;
  margin-top: 24px;
}
.form-error-container {
  margin-bottom: 38px;
}
.create-credential-error-banner {
  display: flex;
  padding: 16px;
  background: #fcebed;
  border: 1px solid #f7d2d7;
  border-radius: inherit;
  line-height: 20px;
  border-radius: 4px;

  .icon {
    margin-right: 10px;

    .icon-alert-filled {
      filter: invert(37%) sepia(22%) saturate(4772%) hue-rotate(326deg) brightness(85%)
        contrast(100%);
    }
  }
}
