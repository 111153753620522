.status-badge-container {
  padding: 4px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;
  width: fit-content;
}
.status-badge-active {
  background-color: #edfcf9;
  p {
    color: #1c806b;
  }
}
.status-badge-inactive {
  background-color: #f3f5fa;
  p {
    color: #353a4a;
  }
}
