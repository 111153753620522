$breakpoint1: 1020px;
$breakpoint2: 944px;
$breakpoint3: 866px;
$breakpoint4: 460px;

.workflows-page-header {
  display: flex;
  flex-direction: column;
  margin-top: 44px;

  .page-header-title {
    font-size: 32px !important;
  }

  .page-header-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    margin-top: 52px;
    .page-header-filters {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
    }

    .page-header-button-search {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-bottom: 16px;

      .workflows-page-header-search {
        width: 100%;
        margin-top: 16px;
        min-width: 218px;
      }
    }

    .page-header-filter-sort {
      display: flex;
      width: 100%;
      .workflows-header-sort-dropdown,
      .workflows-header-filter-active {
        flex: 1;
      }
    }

    .workflows-header-sort-dropdown {
      min-width: 202px;
    }

    .workflows-header-filter-active {
      min-width: 150px;
      margin-right: 16px;
    }
  }
}

@media screen and (min-width: $breakpoint4) {
  .workflows-page-header {
    .page-header-body {
      .page-header-button-search {
        flex-direction: row;

        .workflows-page-header-search {
          margin: 0;
          padding-left: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint3) {
  .workflows-page-header {
    .page-header-body {
      flex-direction: row;

      .page-header-button-search {
        flex-direction: row;
        padding-bottom: 0;

        .workflows-page-header-search {
          width: 100%;
          min-width: 230px;
          padding: 0 16px;
        }
      }

      .page-header-filter-sort {
        width: auto;
        .workflows-header-sort-dropdown,
        .workflows-header-filter-active {
          flex: auto;
        }
      }

      .workflows-header-sort-dropdown {
        min-width: 210px;
      }
    }
  }
}

@media screen and (min-width: $breakpoint2) {
  .workflows-page-header {
    .page-header-body {
      .page-header-button-search {
        flex-direction: row;
      }
    }
  }
}

@media screen and (min-width: $breakpoint1) {
  .workflows-page-header {
    .page-header-body {
      .page-header-button-search {
        flex-direction: row;
      }
    }
  }
}
