.add-asset-link-container {
  margin-top: 24px;
  display: flex;
  align-items: start;

  .add-asset-link-switch {
    margin-top: 3px;
  }

  .add-asset-link-text-container {
    margin-left: 12px;

    .add-asset-link-text-main {
      color: #353a4a;
      margin-bottom: 2px;
    }
    .add-asset-link-text-sub {
      color: #6c7182;
    }
  }
}
