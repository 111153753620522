/* Your styles goes here. */

.admin-ui-container {
  background-color: white;
  height: 100%;
}
.nav-bar {
  display: flex;
  flex-direction: row-reverse;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
  margin-bottom: 12px;
  .nav-link {
    padding: 12px;
    border-left: 1px solid lightgrey;
    &.active {
      border-bottom: 2px solid black;
    }
  }
}

// Styles the toast for IWM notifications
// This should become obsolete if/when the design system introduces a toast component
.Toastify {
  .Toastify__toast-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .Toastify__toast {
      background-color: #252938;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      .Toastify__toast-body {
        color: #fafbfc;
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        .Toastify__toast-icon {
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
      .Toastify__close-button {
        color: #fafbfc;
        opacity: 0.7;
        margin-left: 20px;
        margin-right: 8px;
      }
      button {
        height: 100%;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
