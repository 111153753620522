#select-brandfolder-custom-field {
  max-width: 300px;
  width: 100%;
}

@media (max-width: 768px) {
  #select-brandfolder-custom-field {
    width: 100%;
    max-width: 100%;
  }
}
