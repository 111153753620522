$breakpoint1: 1020px;
$breakpoint2: 944px;
$breakpoint3: 866px;

.workflows-page {
  padding: 40px;
  min-width: 448px;

  .back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: fit-content;
  }
  .workflows-page-breadcrumbs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;

    .caret-icon {
      margin: 0 13px;
      padding-top: 2px;
    }
  }
  .client-name-header {
    font-size: 40px !important;
    margin-top: 20px;
    margin-bottom: 60px;
  }

  .workflow-page-table {
    margin-top: 52px;

    .workflow-row-container {
      th {
        vertical-align: bottom;
      }
    }
    .workflow-page-table-border-bottom tr:last-child {
      border-bottom: 1px solid #e6e6e6;
    }
    .workflow-data-container {
      padding: 15px 12px;
      max-width: 150px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      .workfront-brandfolder-tooltip-container {
        display: flex;
        flex-direction: row;

        span {
          display: flex;
          align-items: center;
        }
        .workfront-brandfolder-tooltip-text-container {
          display: flex;
          align-items: center;
          max-width: calc(100% - 24px);

          span {
            width: 100%;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
            }
          }
        }
      }

      .bf-tooltip__standard--wrapper {
        display: inline;
      }
    }
    .integration-column {
      max-width: 100px;
    }
    .workflow-row-header {
      white-space: normal;
      word-wrap: break-word;
      padding: 15px 12px;
    }
    .total-assets-affected-column,
    .last-updated-column,
    .created-column {
      text-align: right;
    }

    // As we remove columns, we need to adjust table alignment
    .workflow-row-header:not(:first-child):nth-child(5) {
      text-align: right;
    }
    @media screen and (min-width: $breakpoint2) {
      .workflow-row-header:not(:first-child):nth-child(4),
      .workflow-row-header:not(:first-child):nth-child(6) {
        text-align: right;
      }
      .workflow-row-header:not(:first-child):nth-child(5) {
        text-align: left;
      }
    }
    @media screen and (min-width: $breakpoint1) {
      .workflow-row-header:not(:first-child):nth-child(4),
      .workflow-row-header:not(:first-child):nth-child(6),
      .workflow-row-header:not(:first-child):nth-child(7) {
        text-align: right;
      }
      .workflow-row-header:not(:first-child):nth-child(5) {
        text-align: left;
      }
    }
  }
}
