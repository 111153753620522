.add-mapping-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  
  .add-mapping-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }

  .add-mapping-text {
    color: #4462C9;
    margin-left: 10px;
  }
}
