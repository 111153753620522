p.responsive-row-header {
  display: none;
}

@media (max-width: 1038px) {
  .forms-content-container {
    .responsive-row {
      padding: 52px 0;
      border-top: solid 1px #dcdee5;
      border-bottom: solid 1px #dcdee5;

      p.responsive-row-header {
        display: block;
        font-size: 12px;
        line-height: 12px;
        color: #6c7182;
        letter-spacing: 0.5px;
        margin-bottom: 32px;
      }

      .responsive-row-margin {
        margin-top: 52px;
      }
      .sals-bff-arrow-right {
        display: none;
      }
    }
  }
}
