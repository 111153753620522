.create-client-container {
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  .create-client-form-container {
    width: 560px;
    max-height: 95vh;
    overflow-y: auto;
    border-radius: 4px;

    .create-client-form-head {
      padding: 24px;
    }
  }

  .create-client-form-container {
    background: white;
    opacity: 2;
    display: flex;
    flex-direction: column;

    .create-client-form-head {
      padding-top: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: #f9fafc;

      .create-client-form-head-text {
        font-weight: 700;
        font-size: 20px;
        padding-left: 8px;
      }

      .icon-close {
        background: url('assets/close/close-x.svg') center no-repeat;
        min-height: 12px;
        min-width: 12px;
      }

      .add-client {
        align-items: center;
        font-weight: bold;

        .icon-add {
          margin-right: 10px;
          min-height: 12px;
          min-width: 12px;
        }
      }
    }

    .create-client-form {
      background: white;
      opacity: 2;
      padding: 24px;
      padding-bottom: 8px;
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;

      .loader-container {
        // takes up the same amount of space as the elements it replaces so the container doesn't resize
        height: 249px;
      }

      label {
        font-weight: bold;
      }

      select,
      input {
        height: 50px;
        margin-top: 6px;
        padding: 12px;
      }

      .create-client-sub-text {
        font-weight: 450;
        font-size: 12px;
        color: #6c7182;
        margin-top: 8px;

        .error{
          color: #D13649;
          line-height: 16px;
        }
      }

      .space-between {
        justify-content: space-between;
      }
    }

    .section-button {
      background: lightgray;
      border-radius: 5px;
      padding: 5px;
      margin: 24px;
      width: 10em;

      &.active {
        background: purple;
        color: white;
      }
    }
  }
}
