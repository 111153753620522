.workflow-info-container {
  max-width: 685px;

  .workflow-info-selection-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-top: 52px;

    .workflow-info-selection-section-header {
      display: none;
    }

    .hubspot-arrow-icon-container {
      height: 30px;
      align-self: center;

      .arrow-icon {
        margin: auto 36px;
        margin-top: 22px;
        font-size: 14px;
      }
    }

    .workflow-info-selection-row-item {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 400px;

      .workflow-info-selection-row-item-label {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        height: 18px;

        .hubspot-sync-folder-tooltip {
          display: flex;
          margin-bottom: 3px;
        }
      }

      input {
        height: 16px;
      }
    }
  }
  @media (max-width: 1038px) {
    .workflow-info-selection-section {
      padding: 52px 0;
      flex-direction: column;
      align-items: start;
      border-top: solid 1px #dcdee5;
      border-bottom: solid 1px #dcdee5;

      .workflow-info-selection-section-header {
        display: block;
      }

      .hubspot-arrow-icon-container {
        display: none;
      }

      .workflow-info-selection-row-item {
        width: 300px;

        .row-item-responsive-margin {
          margin-top: 52px;
        }
      }
      .workflow-info-selection-row-item:nth-child(2) {
        margin-bottom: 32px;
      }
    }
  }

  @media (max-width: 768px) {
    .workflow-info-selection-section {
      .workflow-info-selection-row-item {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.workflow-optional-info-section {
  margin-top: 52px;
  padding: 26px;
  border-radius: 4px;
  background-color: #fafbfc;
  border: 1px solid #f3f5fa;

  .optional-label {
    display: flex;
    margin-bottom: 20px;

    span {
      margin: auto 5px;
    }
  }

  .option-select-container {
    margin-top: 24px;

    .multi-select-button,
    #select-collections {
      width: 300px;
      margin: 7px 0;
    }
    @media (max-width: 768px) {
      .multi-select-button,
      #select-collections {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

p.section-header-text {
  color: #6c7182;
  letter-spacing: 0.5px;
}
